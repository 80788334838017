export default {
  cake: {
    1313161554: '0x90152046815A6046f59C9294a1893085F85fB64D',
    97: '',
  },
  masterChef: {
    1313161554: '0x1cD746DBeAC96935d0af41B1aD98d5d8180D42e1',
    97: '',
  },
  weth: {
    1313161554: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    97: '',
  },
  aurora: {
    1313161554: '0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
    97: '',
  },
  lottery: {
    1313161554: '',
    97: '',
  },
  lotteryNFT: {
    1313161554: '',
    97: '',
  },
  mulltiCall: {
    1313161554: '0x49eb1F160e167aa7bA96BdD88B6C1f2ffda5212A',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  usdc: {
    1313161554: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
    97: '',
  },
}
