import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'MNS',
    lpAddresses: {
      97: '',
      1313161554: '0x2c820cb319af6d431686d4a9b1c3cd745eda8196', // MNS-USDC LP
    },
    tokenSymbol: 'MNS',
    tokenAddresses: {
      97: '',
      1313161554: '0x90152046815A6046f59C9294a1893085F85fB64D',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'MNS-USDC LP',
    lpAddresses: {
      97: '',
      1313161554: '0x2c820cb319af6d431686d4a9b1c3cd745eda8196',
    },
    tokenSymbol: 'MNS',
    tokenAddresses: {
      97: '',
      1313161554: '0x90152046815A6046f59C9294a1893085F85fB64D',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'MNS-AURORA LP',
    lpAddresses: {
      97: '',
      1313161554: '0xefda355162adba42e14321bc4fa5b9b5de29a77e',
    },
    tokenSymbol: 'MNS',
    tokenAddresses: {
      97: '',
      1313161554: '0x90152046815A6046f59C9294a1893085F85fB64D',
    },
    quoteTokenSymbol: QuoteToken.AURORA,
    quoteTokenAdresses: contracts.aurora,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'WETH-USDC LP',
    lpAddresses: {
      97: '',
      1313161554: '0x2F41AF687164062f118297cA10751F4b55478ae1',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      1313161554: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms
