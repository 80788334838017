import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://www.trisolaris.io/#/swap?outputCurrency=0x90152046815A6046f59C9294a1893085F85fB64D',
      },
      {
        label: 'Liquidity',
        href: 'https://www.trisolaris.io/#/add/ETH/0x90152046815A6046f59C9294a1893085F85fB64D',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Contracts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Token',
        href: 'https://explorer.mainnet.aurora.dev/address/0x90152046815A6046f59C9294a1893085F85fB64D',
      },
      {
        label: 'MasterChef',
        href: 'https://explorer.mainnet.aurora.dev/address/0x1cD746DBeAC96935d0af41B1aD98d5d8180D42e1',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/monstersfinance/',
      },
      {
        label: 'Docs',
        href: 'https://monstersfinance.gitbook.io/monsters-finance/',
      },
      {
        label: 'Blog',
        href: 'https://monstersfinance.medium.com/',
      },
    ],
  },

]

export default config
